export const englishTranslation =
    {
        "timelinePage": {
            "timestamps": {
                "months": {
                    "Jan": "Jan",
                    "Feb": "Feb",
                    "Mar": "Mar",
                    "Apr": "Apr",
                    "May": "May",
                    "Jun": "Jun",
                    "Jul": "Jul",
                    "Aug": "Aug",
                    "Sep": "Sep",
                    "Oct": "Oct",
                    "Nov": "Nov",
                    "Dec": "Dec"
                }
            },
            "emptyTimelineImage": "emptyTimelineEn",
            "scrollNudgeText": "Scroll using the mouse or the touchpad"
        },
        "loginPage": {
            "mainHeader": "Welcome!",
            "secondaryHeader": "Together, we'll go far.",
            "inputPlaceholder": "Mobile phone number",
            "reCaptchaDisclaimerDesktop": `This site is protected by reCAPTCHA and the<br>Google <a href="https://policies.google.com/privacy" target="=_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.`,
            "reCaptchaDisclaimerMobile": `This application is protected by reCAPTCHA and the<br>Google <a href="https://policies.google.com/privacy" target="=_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.`
        },
        "loginValidationPage": {
            "mainHeader": "We've just",
            "secondaryHeader": "sent you a message!",
            "smsPrompt": "Write here the 6-digit code you'll find in the SMS"
        },
        "splashScreenMessages": {
            "weArePreparingYourData": "We are preparing your data",
            "stripePending": "We are preparing a secure payments portal for you",
            "savingChanges": "Saving changes. Do not close the application!",
        },
        "navBar": {
            "timeline": "Timeline",
            "clients": "Clientele",
            "newEntry": "New event",
            "newClient": "New client",
            "profile": "Profile",
        },
        "profilePage": {
            "logout": "Log out",
            "create": "Create",
            "save": "Save",
            "cancel": "Cancel",
            "close": "Close",
            "continue": "Continue",
            "shareToNewUser": "Share to new user",
            "usersWithAccess": "Users with access:",
            "timelineName": "Timeline name",
            "editTimeline": "Timeline editor",
            "newTimelineName": "New timeline name",
            "createNewTimeline": "Create new timeline",
            "didYouFaceAnyIssues": "Report an issue",
            "enterNewUserMobilePhone": "Enter user's mobile phone",
            "archiveTimelineQuestion": "Delete timeline",
            "archive": "Delete",
            "userNoLongerPro": "The user is no longer Pro. For a shared timeline to be accessed, the user should be Pro",
            "sharedBy": "Shared by",
            "sharedToNoone": "Not shared",
            "sharedTo": "Has been shared with",
            "user": "user",
            "users": "users",
            "loading": "(loading...)"
        },
        "actionVerifier": {
            "archiveEntry": "Delete event?",
            "archive": "Delete",
            "removeFile": "Remove file",
            "questionMark": "?",
            "remove": "Remove",
            "cancel": "Cancel",
        },
        "cameraOverlay": {
            "uploadDone": "The photos have been uploaded.<br>You can return back to your computer.",
            "upload": "Upload",
            "photo": "photo",
            "photos": "photos",
            "resetPhotos": "Reset<br>photos",
            "photosUploadingSplasher": "Please wait, your photos are being uploaded!",
            "accessPrompt": "You have to give access to the camera.<br>Check your phone's settings."
        },
        "deletionContainer": {
            "notLoggedIn": "You are not logged in",
            "mustBeLoggedIn": "You have to be logged in to request deletion of your data",
            "loginHere": `You can log in by clicking <a href="." id="redirectLogin">here</a>`,
            "deletionToHappenAfter7days": "Your data will be deleted after 7 days.",
            "contactUs": "Contact us",
            "detailsToCancelDeletion": "in time within this 7 day period in case you want to cancel your data deletion.",
            "deleteData": "Data deletion",
            "allDataWillBeDeleted": "All of your data will be deleted from BLE",
            "sendingDeletionRequest": "Requesting data deletion...",
            "notYourNumber": "This is not the number associated with your account!",
            "user": "User",
            "emailSubject": "Data deletion request",
            "phoneInputPlaceholder": "Mobile phone number",
        },
        "pwaGuard": {
            "text": "Install application"
        },
        "upgradePage": {
            "continue": "Continue",
            "accessSharedTimelines": "Access shared timelines!",
            "shareYourTimelines": "Share your timelines!",
            "unlimitedTimelines": "Multiple timelines!",
            "perk1prov": "Clientele with file record!",
            "perk2prov": "Timeline sharing!",
            "perk3prov": "Multiple timelines!",
            "price": "0.99",
            "billingPeriod": " € / month",
            "callToAction": "Be a Pro!",
            "callToActionProv": "Subscription has ended :(",
            "callToActionProv2": "If you liked us, you can stay!",
        },
        "eventEditor": {
            "close": "Close",
            "back": "Back",
            "cancel": "Cancel",
            "save": "Save",
            "mobilePhotoTransferCancelationInstruction": "<br>To cancel the upload procedure, press on the 'back' button",
            "mobileTransferStep4": "4. The photos will appear here automatically",
            "mobileTransferStep3": `3. Click on the button "Upload Χ photos"`,
            "mobileTransferStep2": "2. Take a number of photos",
            "mobileTransferStep1": "1. Scan the QR code below",
            "userSmartphoneInstruction": "Use any smartphone in order to upload files:",
            "uploadThroughCamera": "Phone camera",
            "uploadFiles": "Add files",
            "newTimelineNamePlaceholderText": "New timeline name",
            "calendarPlaceholderLabelText": "Event datetime",
            "eventDescriptionPlaceholderText": "Event comments",
            "eventTitlePlaceholderText": "Event title",
            "editorModalHeaderNewEvent": "New event",
            "editorModalHeaderEditEvent": "Edit event",
            "theFile": "The file",
            "isNotSupported": "is not supported. Supported files are",
            "isTooBig": "is too big! Maximum allowed size is 15 megabytes per file.",
            "createdFrom": "Last edited from",
            "youNeedFullAccessTitle": "You need full timeline access!",
            "youNeedFullAccessDescription": "In order to see entries from other people in the user's timeline, the owner must be Pro and share the timeline with you.",
            "timelineIsArchivedTitle": "This timeline is deleted!",
            "timelineIsArchivedDescription": "You can only see your own entries since the owner of this timeline has deleted it.",
        },
        "registrationPage": {
            "aQuestion": "A question",
            "whatDescribesYouBest": "what describes you best?",
            "client": "Personal use",
            "clientDescription": "I will be meddling with entries related to myself",
            "provider": "Commercial use",
            "providerDescription": "I will be creating entries with regards to my clientele",
            "iAcceptTheTermsAndConditions": `I accept the <a id="legalLink" class="legal" href="">Terms & Conditions</a>`,
            "free": "free",
            "threeMonthsFree": "3 months free",
            "wrongOtp": "Wrong code. Try again.",
            "otpTimeout": "The time limit for entering the code has expired."
        },
        "legal": {
            "translation1": `<b>BLE Terms of Service</b>`,
            "translation2": `Effective Date: January 1, 2023`,
            "translation3": `<b>1. Introduction</b>`,
            "translation4": `Thanks for using BLE. BLE is a service provided by Protevon ("<strong>BLE</strong>", "<strong>we</strong>", "<strong>us</strong>", “<strong>εμείς</strong>” or “<strong>μας</strong>”), located at 8 Zoodochou Pigis, 56224, Thessaloniki, Greece. These Terms of Service for BLE (referred to as the "<strong>Terms</strong>") cover your use and access to BLE and your content within BLE.`,
            "translation5": `All Terms and Policies hereinafter are formulated in the Greek language. Your use of BLE implies your acceptance of these Terms. Please read them carefully. If you do not understand or accept any part of these Terms, then you should not use BLE. <br><br> Our <a href="#privacy">Privacy Policy</a> explains how we collect and use your information, while our <a href="#AbusePolicy">Program Policies</a> outline your responsibilities when using our Service`,
        },
        "showcaseAssistant": {
            "guidedTours_v1_2": `Click on an event to see all its details or to edit it!`,
            "guidedTours_v1_1": `Create a new event by clicking on +`,
            "guidedTours_v1_3": `You can have multiple personal timelines and share them too, through your profile page`,
            "guidedTours_v1_4": `You can call a client by performing a long-press!`,
            "guidedTours_v1_4_2": `GOT IT!`,
        },
        "demoData": {
            "timelineName": "My personal timeline",
            "event1title": "Example event 1",
            "event1description": " ",
            "event2title": "Example event 2",
            "event2description": "The second event has a description! You can input as much information you'd like in every event.",
            "event3title": "Example event 3",
            "event3description": "The third event has files too! You can attach existing files or create new ones using a phone's camera.",
        },
        "announcementAssistant": {
            "trialAwarenessHeader": "Welcome, here's a gift!",
            "trialAwarenessDescription": "Upon registering you get 3 months of PRO on us! By being a PRO user you can:",
            "trialAwarenessDescriptionProv": "Upon registering you get 3 months of the commercial package on us! With it you can:",
            "perk1": "Have multiple timelines",
            "perk2": "Share your timelines",
            "perk3": "Access timelines shared to you",
            "perk1prov": "Maintain a clientele with event history per client",
            "perk2prov": "Keep a file record (images, videos, docs) per event",
            "perk3prov": "Create multiple timelines for both your clients and your own personal use",
            "confirmationButton": "Got it",
        },
        "userNotifier": {
            "attention": "Attention!",
            "usersDoNotExist": "The timeline was not shared to the following users because they don't exist",
            "ownerNoLongerProTitle": "Owner no longer Pro!",
            "ownerNoLongerProDescription": "The owner of this timeline has shared it with you but is no longer Pro. For a timeline to be shared the owner must be Pro",
        },
        "networkGuard": {
            "title": "Oh Dear!",
            "description": "Slow or no internet connection.",
        },
        "successPage": {
            "title": "Congratulations!",
            "description": "You are now a PRO",
            "button": "Continue",
        },
        "selfRenamer": {
            "username": "What is your name?",
            "cancel": "Cancel",
            "save": "Save",
        },
        "userCreator": {
            "newClient": "New client",
            "phoneNumber": "Phone number",
            "cancel": "Cancel",
            "addClient": "Add client",
            "fullname": "Full name",
            "firstTimelineName": "First timeline name",
        },
        "clientele": {
            "clienteleHeader": "Clientele",
            "clienteleEventDateColumnHeader": "EVENT DATE",
            "clienteleEventsTimelinesColumnHeader": "EVENTS(TIMELINES)",
            "clienteleNameColumnHeader": "NAME",
            "clientelePhoneColumnHeader": "PHONE",
            "searchPlaceholderClientele": "Search",
            "events": "events",
            "event": "event",
            "timelines": "timelines",
            "timeline": "timeline",
            "newEvent": "New event",
            "new": "New",
            "Timelines": "Timelines",
            "noNameAvailable": "(Name not available)",
            "emptyClienteleImage": "emptyClienteleEn",
            "in": "in"
        },
        "timelineShowcaser": {
            "whichTimelineAddEvent": "At which timeline would you like to add the event?",
            "timeslinesOfUser": "Timelines of",
            "createNewTimeline": "Create new timeline",
            "entries": "event/s",
            "fromYou": "from you",
        },
    "shareMobileDownload": {
    "imgSrc": "splashEn",
},
    }
